<template>
  <div class="page">
    <header>
      <div>
        <h1 class="logo">BoardStreams.com</h1>
        <div>
          <template v-if="!myUserId">not logged in</template>
          <template v-else>
            Welcome, <ShowUser :user-id="myUserId" />
            [<a href="#" @click.prevent="openRename" style="color: red">rename</a>]
          </template>
        </div>
      </div>
    </header>

    <nav>
      <div>
        <router-link to="/">Home</router-link> |
        <router-link to="/chat">Chat</router-link> |
        <router-link to="/tic-tac-toe">Tic-Tac-Toe</router-link>
      </div>
    </nav>

    <main>
      <div>
        <router-view @notify="notify" />
      </div>
    </main>

    <footer>
      <div>
        <div class="left">
          &copy; 2021 KarelCom OÜ
        </div>
        <div class="right">
          Made with the <ext-link to="https://boardstreams.dev">BoardStreams</ext-link> library,
          December 2021
        </div>
      </div>
    </footer>
  </div>

  <teleport to="#disconnect-overlay">
    <div class="disconnect-overlay" v-if="!connected">
      <div>
        <p>Connecting...</p>
        <p>
          <box-icon name="loader-circle" color="white" size="400%" animation="spin" />
        </p>
      </div>
    </div>
  </teleport>

  <MyModal v-if="renameModalVisible" @close="renameModalVisible = false">
    <template #header>Set name</template>

    <p>
      Names are not unique, and you can still change them afterwards.
    </p>

    <p>
      Please enter your name (1-20 letters, digits, or spaces):
    </p>

    <form @submit.prevent="submitRename" id="rename-form">
      <input type="text" v-model="newUsername" placeholder="Name..."
             maxlength="20" style="width: 100%;" v-focus ref="renameInput" />
    </form>

    <template #footer>
      <button style="color: red;" @click="renameModalVisible = false">Cancel</button>&#x200b;
      <button style="color: green;" form="rename-form">Submit</button>
    </template>
  </MyModal>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue';
import { useObservable } from '@vueuse/rxjs';
import {
  merge, of, Subject, fromEvent,
} from 'rxjs';
import { mapTo, withLatestFrom, filter } from 'rxjs/operators';
import { useTitle } from '@vueuse/core';
import BS, { connectedUsersStream, myProfile, myUserId } from './libs/BS';

const connected = useObservable(BS.connected$);
const renameModalVisible = ref(false);
const newUsername = ref(undefined);
const renameInput = ref();

const title = useTitle();
const notifies$ = new Subject();

function notify() {
  notifies$.next(true);
}

const hasFocus$ = merge(
  of(document.hasFocus()),
  fromEvent(window, 'focus').pipe(mapTo(true)),
  fromEvent(window, 'blur').pipe(mapTo(false)),
);

// load beep sound
const bleep = window.Audio ? new Audio('/bleep.mp3') : null;

// notify at the right times
notifies$.pipe(
  withLatestFrom(hasFocus$),
  filter(([, hasFocus]) => hasFocus === false),
).subscribe(() => {
  if (!(/^\* /.test(title.value))) {
    title.value = `* ${title.value}`;
    bleep.play();
  }
});

// remove star (*) from title bar at the right times
hasFocus$.pipe(
  filter((hasFocus) => hasFocus === true),
).subscribe(() => {
  title.value = title.value.replace(/^\* /, '');
});

async function openRename() {
  newUsername.value = /^X-/.test(myProfile.value.username) ? '' : myProfile.value.username;
  renameModalVisible.value = true;
  await nextTick();
  renameInput.value.setSelectionRange(0, renameInput.value.value.length);
}

watch(
  () => myProfile.value?.username,
  (username) => {
    if (username?.match(/^X-/)) {
      openRename();
    }
  },
);

async function submitRename() {
  await connectedUsersStream.doRequest('rename', newUsername.value);
  renameModalVisible.value = false;
}
</script>

<style lang="scss" scoped>
$page-width: 1280px;
$max-page-width: 95%;
$min-desktop: 600px;

.page {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: blue;
  color: white;
  padding: 8px 0;

  & > div {
    width: $page-width;
    max-width: $max-page-width;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $min-desktop) {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }

    h1 {
      margin: 0;
    }
  }
}

nav {
  background-color: #ccc;

  & > div {
    width: $page-width;
    max-width: $max-page-width;
    margin: 0 auto;
  }
}

main {
  flex: 1 0 0;
  padding: 15px 0;

  display: flex;
  flex-direction: column;

  & > div {
    flex: 1 0 0;

    height: 100%;
    width: $page-width;
    max-width: $max-page-width;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
  }
}

footer {
  background-color: #ccc;
  padding: 8px 0;

  & > div {
    width: $page-width;
    max-width: $max-page-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}

.disconnect-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-weight: bold;
  font-size: 600%;
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
