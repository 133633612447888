<template>
  <table border="1" v-if="summary">
    <tr v-for="(playerCode, idx) in ['x', 'o']" :key="playerCode">
      <th>{{ playerCode.toUpperCase() }}:</th>
      <td>
        <template v-if="summary.players[playerCode]">
          <ShowUser :user-id="summary.players[playerCode]" />&#x200b;
          <button v-if="meSitting && summary.players[playerCode] === myUserId && !summary.started"
                  @click="stand">
            Stand
          </button>
        </template>
        <button v-else-if="meSitting || !(Object.values(summary.players).includes(myUserId))"
                @click="sit(playerCode)">Sit</button>
      </td>
      <td v-if="summary.wantToStart && ! summary.started">
        <template v-if="summary.wantToStart[playerCode]">
          <span class="wants-to-start">
            <ShowUser :user-id="summary.players[playerCode]" /> wants the game to start
          </span>
          <button @click="wantToStart(playerCode, false)"
                  v-if="meSitting && summary.players[playerCode] === myUserId">
            Don't start the game
          </button>
        </template>
        <template v-else>
          <span class="doesnt-want-to-start">
            <ShowUser :user-id="summary.players[playerCode]" /> hasn't clicked Start yet
          </span>
          <button v-if="meSitting && summary.players[playerCode] === myUserId"
                  @click="wantToStart(playerCode, true)">Start the game</button>
        </template>
      </td>
      <td v-if="summary.started && idx === 0" rowspan="2">
        Game started
      </td>
    </tr>
  </table>
</template>

<script>
import {
  onBeforeUnmount, watch, toRef, computed,
} from 'vue';
import { useObservable, from, useSubscription } from '@vueuse/rxjs';
import { switchMap, filter, share } from 'rxjs/operators';
import BS, { myUserId } from '../libs/BS';

const streams = {};

export default {
  props: {
    tableId: {
      type: String,
      required: true,
    },
    meSitting: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['sat', 'stood', 'started'],
  setup(props, { emit, expose }) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const tableId = toRef(props, 'tableId');

    function unfollow(tid) {
      if (!tid) return;
      streams[tid].num -= 1;
      if (!streams[tid].num) {
        streams[tid].stream.destroy();
        delete streams[tid];
      }
    }

    watch(
      tableId,
      (newValue, oldValue) => {
        // unfollow old
        unfollow(oldValue);

        if (!newValue) return;
        // follow new
        if (!(newValue in streams)) {
          streams[newValue] = {
            stream: BS.newStream(`games:tic_tac_toe:table:${newValue}:summary`),
            num: 1,
          };
          streams[newValue].stream.join();
        } else {
          streams[newValue].num += 1;
        }
      },
      { immediate: true },
    );

    const stream = computed(() => streams[tableId.value]?.stream);

    function wantToStart(playerCode, value) {
      stream.value.doAction('want_to_start', [playerCode, value]);
    }

    const summary = useObservable(
      from(stream, { immediate: true }).pipe(
        switchMap((s) => s.state$),
      ),
    );

    const events$ = from(stream, { immediate: true }).pipe(
      switchMap((s) => s.events$),
      share(),
    );

    useSubscription(
      events$.pipe(
        filter((x) => x.type === 'started'),
      ).subscribe(() => emit('started')),
    );

    onBeforeUnmount(() => unfollow(tableId.value));

    async function sit(playerCode) {
      await stream.value.doRequest('sit', playerCode);
      emit('sat', playerCode);
    }

    async function stand() {
      await stream.value.doRequest('stand');
      emit('stood');
    }

    expose({ summary, sit, stand });

    return {
      summary, myUserId, wantToStart, sit, stand,
    };
  },
};
</script>

<style lang="scss" scoped>
.table {
  button {
    margin: 0 auto;
  }
}

.wants-to-start {
  color: green;
}

.doesnt-want-to-start {
  color: red;
}
</style>
