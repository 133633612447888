export function debug(...args) {
  if (process?.env?.NODE_ENV === 'development') console.debug(...args);
}

const uniqueIdLimit = 2 ** 50;
let uniqueIdCursor = 1;
export function uniqueID() {
  // eslint-disable-next-line no-plusplus
  const ret = uniqueIdCursor++;
  if (uniqueIdCursor > uniqueIdLimit) uniqueIdCursor = 1;
  return String(ret);
}

export function concatArr8(arr1, arr2) {
  const tempArr = new Uint8Array(arr1.length + arr2.length);
  tempArr.set(arr1, 0);
  tempArr.set(arr2, arr1.length);
  return tempArr;
}

export function dclone(thing) {
  return JSON.parse(JSON.stringify(thing));
}
