<template>
  <span :title="`userId: ${userId}`">{{ profile?.username }}</span>
</template>

<script>
import {
  onBeforeUnmount, watch, toRef,
} from 'vue';
import { useObservable, from } from '@vueuse/rxjs';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import BS from '../libs/BS';

const streams = {};

export default {
  props: {
    userId: {
      type: [String, null],
      required: true,
    },
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const userId = toRef(props, 'userId');

    function unfollow(uid) {
      if (!uid) return;
      streams[uid].num -= 1;
      if (!streams[uid].num) {
        streams[uid].stream.destroy();
        delete streams[uid];
      }
    }

    watch(
      userId,
      (newValue, oldValue) => {
        // unfollow old
        unfollow(oldValue);

        // follow new
        if (!newValue) return;
        if (!(newValue in streams)) {
          streams[newValue] = {
            stream: BS.newStream(`user:${newValue}`),
            num: 1,
          };
          streams[newValue].stream.join();
        } else {
          streams[newValue].num += 1;
        }
      },
      { immediate: true },
    );

    const profile = useObservable(
      from(userId, { immediate: true }).pipe(
        switchMap((uid) => (uid ? streams[uid].stream.state$ : EMPTY)),
      ),
    );

    onBeforeUnmount(() => unfollow(userId.value));

    return { profile };
  },
};
</script>
