<template>
  <div class="main" v-if="streamState">
    <div class="top-pane">
      <div class="messages-container">
        <div class="messages" ref="messagesDiv">
          <div class="load-previous-messages" ref="loadPrevBar" v-if="previousMessagesExist">
            Load previous messages...
          </div>

          <div class="expand-button" :class="{ collapsed }">
            <box-icon name="left-arrow-alt" @click="collapsed = false" />
          </div>

          <div v-for="thing in messages" :key="thing.id">
            <!-- messages -->
            <div v-if="'text' in thing" class="message">
              <span class="timestamp">
                [<MyDate :time="thing.timestamp" format="D/M/YYYY h:mma" />]
              </span>&#x200b;
              <ShowUser :user-id="thing.userId" />:
              {{ thing.text }}
            </div>

            <!-- join/leave notices -->
            <div v-else class="joins-and-leaves">
              *
              <template v-for="(userIds, jlState, idx1) in thing.data" :key="jlState">
                <template v-for="(userId, idx) in userIds" :key="userId">
                  <ShowUser :user-id="userId" />
                  <template v-if="idx < userIds.length - 2">, </template>
                  <template v-else-if="idx === userIds.length - 2"> and </template>
                </template>&#x200b;
                <span>{{ joinAndLeaveActions[jlState] }}</span>
                <template v-if="idx1 < Object.keys(thing.data).length - 2">, </template>
                <template v-if="idx1 === Object.keys(thing.data).length - 2"> and </template>
              </template>
            </div>
          </div>

          <div v-if="Object.keys(joinsAndLeaves).length" class="joins-and-leaves">
            *
            <template v-for="(userIds, jlState, idx1) in joinsAndLeaves" :key="jlState">
              <template v-for="(userId, idx) in userIds" :key="userId">
                <ShowUser :user-id="userId" />
                <template v-if="idx < userIds.length - 2">, </template>
                <template v-else-if="idx === userIds.length - 2"> and </template>
              </template>&#x200b;
              <span>{{ joinAndLeaveActions[jlState] }}</span>
              <template v-if="idx1 < Object.keys(joinsAndLeaves).length - 2">, </template>
              <template v-if="idx1 === Object.keys(joinsAndLeaves).length - 2"> and </template>
            </template>
          </div>

          <div class="who-is-typing" :class="{ show: typingUserIds.length }">
            <template v-for="(userId, idx) in typingUserIds" :key="userId">
              <ShowUser :user-id="userId" />
              <template v-if="idx < typingUserIds.length - 2">,</template>
              <template v-else-if="idx === typingUserIds.length - 2"> and </template>
            </template>
            {{ typingUserIds.length > 1 ? 'are' : 'is' }} typing...
          </div>
        </div>

        <div class="missed-messages-notification" v-if="missedMessages"
             @click="scrollToBottom">
          {{ missedMessages }} missed message{{ missedMessages !== 1 ? 's' : '' }}
          <box-icon name="chevrons-down" color="white" />
        </div>
      </div>

      <!-- users list -->
      <div class="users-list" :class="{ collapsed }">
        <div class="users-close">
          <box-icon name="x" @click="collapsed = true" />
        </div>
        <template v-if="streamState">
          <div v-for="(_, userId) in streamState.users" :key="userId">
            <ShowUser :user-id="userId" />
          </div>
        </template>
      </div>
    </div>
    <div class="input-line">
      <form @submit.prevent="say" v-if="myProfile">
        <span>
          <ShowUser :user-id="myUserId" />:
        </span>
        <input type="text" v-model="inputMsg" ref="inputBox" v-focus />
        <button type="submit">Say</button>
      </form>
      <div v-else style="visibility: hidden;">
        <input type="text">
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref, onBeforeUnmount, computed, nextTick,
} from 'vue';
import {
  useObservable, from, useSubscription, toObserver, fromEvent,
} from '@vueuse/rxjs';
import { EMPTY } from 'rxjs';
import {
  throttleTime, pairwise, filter, switchMap, catchError, distinctUntilChanged,
  map, debounceTime, startWith, take,
} from 'rxjs/operators';
import BS, { myUserId, myProfile } from '../libs/BS';

// eslint-disable-next-line no-undef
const emit = defineEmits(['notify']);

const inputMsg = ref('');
const inputBox = ref();
const messages = ref([]);
const messagesDiv = ref();
const missedMessages = ref(0);
const loadPrevBar = ref();
const previousMessagesExist = ref(true);
const collapsed = ref(true);

const stream = BS.newStream('chat');
stream.join();

const streamState = useObservable(stream.state$);

const joinsAndLeavesStream = BS.newStream('chat:joins_and_leaves');
joinsAndLeavesStream.join();
function formJoinsAndLeaves(jlState) {
  const obj = {};
  Object.entries(jlState).forEach(([userId, num]) => {
    obj[num] ??= [];
    obj[num].push(userId);
  });
  return obj;
}

const joinsAndLeaves = useObservable(
  joinsAndLeavesStream.state$.pipe(
    startWith({}),
    map((jlState) => formJoinsAndLeaves(jlState)),
  ),
);

// notify when user is typing
from(inputMsg, { immediate: true }).pipe(
  pairwise(),
  filter(([oldStr, newStr]) => newStr.length > oldStr.length),
  throttleTime(1e3),
).subscribe(() => stream.doAction('i_am_typing'));

const typingUserIds = computed(() => {
  const userIds = Object.values(streamState.value?.typing ?? {})
    .flat()
    .filter((userId) => userId !== myUserId.value);
  return [...new Set(userIds)].sort(); // unique
});

async function say() {
  if (!inputMsg.value) return;
  await stream.doRequest('say', inputMsg.value);
  inputMsg.value = '';
}

async function scrollToBottom({ fast }) {
  missedMessages.value = 0;
  await nextTick();
  messagesDiv.value.scroll({
    top: messagesDiv.value.scrollHeight,
    left: 0,
    behavior: fast ? 'instant' : 'smooth',
  });
}

useSubscription(
  from(messagesDiv, { immediate: true }).pipe(
    filter((x) => !!x),
    take(1),
  ).subscribe(() => scrollToBottom({ fast: true })),
);

stream.on('eventWithId', async ({ event, id }) => {
  if (event.type === 'said') {
    const { timestamp, userId, message } = event;
    messages.value.push({
      id, timestamp, userId, text: message,
    });
    if (messagesDiv.value) {
      const { scrollTop, scrollHeight, clientHeight } = messagesDiv.value;
      if (scrollTop > scrollHeight - clientHeight - 50) {
        await scrollToBottom({ fast: true });
      } else {
        missedMessages.value += 1;
      }
    } else {
      await nextTick();
    }
    emit('notify');
  } else if (event.type === 'joins_and_leaves') {
    messages.value.push({
      id,
      type: event.type,
      data: formJoinsAndLeaves(event.data),
    });
  }
});

// load previous messages when clicked
useSubscription(
  fromEvent(loadPrevBar, 'click').pipe(
    switchMap(
      () => from(
        stream.doRequest('fetch_prev_msgs', messages.value[0].id),
      ).pipe(
        catchError(() => EMPTY),
      ),
    ),
  ).subscribe(async (prevMessages) => {
    const { scrollTop, scrollHeight } = messagesDiv.value;
    messages.value.unshift(...prevMessages);
    if (!prevMessages.length) previousMessagesExist.value = false;
    // preserve viewport position
    await nextTick();
    const scrollHeight2 = messagesDiv.value.scrollHeight;
    messagesDiv.value.scroll(0, scrollHeight2 - scrollHeight + scrollTop + 1);
  }),
);

// set missedMessages to 0 when scrolled to bottom
useSubscription(
  from(missedMessages, { immediate: true }).pipe(
    map((x) => x !== 0),
    distinctUntilChanged(),
    switchMap((x) => {
      if (x === false) return EMPTY;
      return fromEvent(messagesDiv, 'scroll');
    }),
    debounceTime(100),
  ).subscribe(() => {
    const { scrollTop, scrollHeight, clientHeight } = messagesDiv.value;
    if (scrollHeight - clientHeight === scrollTop) missedMessages.value = 0;
  }),
);

// hide the "load previous messages" bar when there are none
useSubscription(
  from(
    computed(() => messages.value[0]?.id),
    { immediate: true },
  ).pipe(
    distinctUntilChanged(),
    filter((x) => x !== undefined),
    switchMap(
      (firstId) => from(
        stream.doRequest('do_previous_messages_exist', firstId),
      ).pipe(
        catchError(() => EMPTY),
      ),
    ),
  ).subscribe(toObserver(previousMessagesExist)),
);

onBeforeUnmount(() => {
  stream.destroy();
  joinsAndLeavesStream.destroy();
});

const joinAndLeaveActions = {
  2: 'joined',
  1: 'popped out for a second',
  '-1': 'popped in for a second',
  '-2': 'left',
};
</script>

<style lang="scss" scoped>
$min-desktop: 600px;

div.main {
  flex: 1 0 0;

  border: 5px solid green;

  display: flex;
  flex-direction: column;

  .top-pane {
    flex: 1 0 0;

    display: flex;

    .messages-container {
      position: relative;

      flex: 1 1 auto;

      display: flex;
      flex-direction: column;

      .messages {
        flex: 1 0 0;
        height: 500px;
        overflow-y: auto;
        position: relative;

        padding: 3px 5px;

        .who-is-typing {
          font-size: smaller;
          color: #777;
          background-color: #ffa;

          &:not(.show) {
            visibility: hidden;
          }
        }
      }
    }

    .users-list {
      width: 250px;
      min-width: 250px;
      border-left: 5px solid green;
      padding: 3px 5px;

      @media (max-width: $min-desktop) {
        &.collapsed {
          display: none;
        }
        width: 150px;
        min-width: 150px;
      }

      .users-close {
        cursor: pointer;
        float: right;

        @media (min-width: $min-desktop) {
          display: none;
        }
      }
    }

    .expand-button {
      display: none;
      cursor: pointer;

      @media (max-width: $min-desktop) {
        display: block;
        position: sticky;
        float: right;
        top: 0;
        right: 0;
      }

      &:not(.collapsed) {
        display: none;
      }
    }
  }

  .input-line {
    border-top: 5px solid green;
    padding: 3px 5px;

    form {
      display: flex;
      gap: 6px;

      & > input {
        flex: 1 1 0;
        width: 10px;
      }
    }
  }
}

.message {
  .timestamp {
    color: #888;
    font-family: monospace;
  }
}

.joins-and-leaves {
  color: #aaa;
  font-style: italic;
}

.missed-messages-notification {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 3px 5px;

  background-color: red;
  font-weight: bold;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.load-previous-messages {
  background-color: #ccc;
  padding: 3px 5px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-style: italic;
  color: #555;
}
</style>
