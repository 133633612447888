<template>
  <div>
    <h3>Tic-Tac-Toe Board</h3>

    <div v-if="game">
      <table class="players">
        <tr v-for="(playerCode, userId) in game.players" :key="playerCode"
          :class="{special: game.moves === playerCode && userId === myUserId}">
          <td><ShowUser :user-id="userId" /></td>
          <td style="font-weight: bold;">{{ playerCode.toUpperCase() }}</td>
          <td v-if="game.moves">
            {{ game.moves === playerCode ? '&lt;- Plays' : '' }}
          </td>
          <td v-if="summary?.winner === playerCode">
            <span class="winner">WINNER!!!</span>
            (<router-link to="/tic-tac-toe">go back</router-link>)
          </td>
        </tr>
      </table>

      <table class="board" v-if="1">
        <tr>
          <td @click="play(0)" class="square" :class="{clickable: myTurn && !calcPawn(0)}">
            {{ calcPawn(0) }}
          </td>
          <td>❕</td>
          <td @click="play(1)" class="square" :class="{clickable: myTurn && !calcPawn(1)}">
            {{ calcPawn(1) }}
          </td>
          <td>❕</td>
          <td @click="play(2)" class="square" :class="{clickable: myTurn && !calcPawn(2)}">
            {{ calcPawn(2) }}
          </td>
        </tr>
        <tr>
          <td>➖</td>
          <td>➕</td>
          <td>➖</td>
          <td>➕</td>
          <td>➖</td>
        </tr>
        <tr>
          <td @click="play(3)" class="square" :class="{clickable: myTurn && !calcPawn(3)}">
            {{ calcPawn(3) }}
          </td>
          <td>❕</td>
          <td @click="play(4)" class="square" :class="{clickable: myTurn && !calcPawn(4)}">
            {{ calcPawn(4) }}
          </td>
          <td>❕</td>
          <td @click="play(5)" class="square" :class="{clickable: myTurn && !calcPawn(5)}">
            {{ calcPawn(5) }}
          </td>
        </tr>
        <tr>
          <td>➖</td>
          <td>➕</td>
          <td>➖</td>
          <td>➕</td>
          <td>➖</td>
        </tr>
        <tr>
          <td @click="play(6)" class="square" :class="{clickable: myTurn && !calcPawn(6)}">
            {{ calcPawn(6) }}
          </td>
          <td>❕</td>
          <td @click="play(7)" class="square" :class="{clickable: myTurn && !calcPawn(7)}">
            {{ calcPawn(7) }}
          </td>
          <td>❕</td>
          <td @click="play(8)" class="square" :class="{clickable: myTurn && !calcPawn(8)}">
            {{ calcPawn(8) }}
          </td>
        </tr>
      </table>
      {{game}}
    </div>
  </div>
</template>

<script setup>
import { computed, watch, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { useObservable } from '@vueuse/rxjs';
import BS, { myUserId } from '../libs/BS';

const route = useRoute();
const tableId = computed(() => route.params.tableId);

const boardStream = BS.newStream(`games:tic_tac_toe:table:${tableId.value}`);
const game = useObservable(boardStream.state$);
boardStream.join();

const summaryStream = BS.newStream(`games:tic_tac_toe:table:${tableId.value}:summary`);
const summary = useObservable(summaryStream.state$);
summaryStream.join();

const myPlayerCode = computed(() => game.value?.players[myUserId.value]);

watch(
  myPlayerCode,
  async (newVal, oldVal) => {
    if (newVal && !oldVal) {
      await summaryStream.doRequest('sit', newVal);
    }
  },
);

const board = computed(() => game.value.board);

function calcPawn(p) {
  if (board.value[p] === 'x') return '❌';
  if (board.value[p] === 'o') return '⭕';
  return null;
}

const myTurn = computed(() => game.value.moves === game.value.players?.[myUserId.value]);

async function play(p) {
  await boardStream.doRequest('move', p);
}

onBeforeUnmount(() => {
  boardStream.destroy();
  summaryStream.destroy();
});
</script>

<style lang="scss" scoped>
table.players {
  margin: 20px 0;
  border-collapse: collapse;

  tr.special {
    background-color: #fcc;
  }

  th, td {
    border: 1px solid black;

    winner {
      color: red;
      font-weight: bold;
      font-size: larger;
    }
  }
}

table.board {
  margin: 45px auto;
  font-size: xx-large;
  line-height: 100%;

  td.square.clickable {
    cursor: pointer;
  }
}
</style>
