import {
  Observable, defer, concat, timer, of,
} from 'rxjs';
import {
  repeat, ignoreElements, tap, distinctUntilChanged, startWith,
} from 'rxjs/operators';

import { debug } from './Utils';

function makeWebSocketObservable(url) {
  return new Observable((subscriber) => {
    const ws = new WebSocket(url);

    ws.addEventListener('open', () => {
      debug('WebSocket connection opened');
      ws.binaryType = 'arraybuffer';
      subscriber.next(ws);
    });

    ws.addEventListener('close', () => {
      debug('WebSocket connection closed');
      subscriber.complete();
    });

    ws.addEventListener('error', () => {
      debug('WebSocket connection emitted an error - will soon emit a close');
    });

    return () => {
      ws.close();
    };
  });
}

export default function makeWebSocketsObservable(url) {
  return new Observable((subscriber) => {
    const delays = [0, 1e3, 2e3, 3e3, 4e3, 5e3];
    let numFailures = 0;

    const s = defer(() => concat(
      makeWebSocketObservable(url),
      of(null),
      defer(() => {
        // eslint-disable-next-line no-plusplus
        const delay = delays[numFailures++] ?? delays[delays.length - 1];
        return timer(delay).pipe(ignoreElements());
      }),
    )).pipe(
      tap((x) => { if (x) numFailures = 0; }),
      repeat(),
      startWith(null),
      distinctUntilChanged(),
    ).subscribe(subscriber);

    return () => { s.unsubscribe(); };
  });
}
