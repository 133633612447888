// this function both modifies in place, and returns the new modified value,
// so that if the value of struct itself changes from an object to an array
// (or vice versa) you'll have access to it.

function defaultSetter(obj, key, value) {
  // eslint-disable-next-line no-param-reassign
  obj[key] = value;
}
function defaultDeleter(obj, key) {
  // eslint-disable-next-line no-param-reassign
  delete obj[key];
}

// implement "patch" from https://metacpan.org/release/Struct-Diff/source/lib/Struct/Diff.pm
export default function (struct, diff, setter = defaultSetter, deleter = defaultDeleter) {
  if (diff == null) return struct;

  const envelopeStruct = { struct };

  const stack = [[[envelopeStruct, 'struct'], diff]];

  while (stack.length) {
    // 413
    const [sRef, d] = stack.shift(); // 414
    const [parentObj, key] = sRef;
    const s = parentObj[key];

    if ('D' in d) {
      if (
        // if ref ${$s} ne ref $d->{D}... (line 417)
        (Array.isArray(s) && !Array.isArray(d.D))
        || (typeof s === 'object' && s !== null && !(typeof d.D === 'object' && d.D !== null))
      ) {
        throw new Error('Structure does not match');
      }

      if (Array.isArray(d.D)) {
        // 419
        let [i, j] = [0, 0]; // target array idx, jitter

        d.D.forEach((_) => {
          // 422
          if ('I' in _) i = _.I + j;

          if ('D' in _ || 'N' in _) {
            stack.push([[s, `${i}`], _]); // 426
          } else if ('A' in _) {
            s.splice(i, 0, _.A);
            j += 1;
          } else if ('R' in _) {
            s.splice(i, 1);
            j -= 1;
            return;
          }

          i += 1; // 436
        });
      } else {
        // 438
        Object.keys(d.D).forEach((k) => {
          const v = d.D[k];

          if ('D' in v || 'N' in v) {
            // 440
            stack.push([[s, k], v]);
          } else if ('A' in v) {
            setter(s, k, v.A);
          } else if ('R' in v) {
            deleter(s, k);
          }
        });
      }
    } else if ('N' in d) {
      setter(parentObj, key, d.N);
    }
  }

  return envelopeStruct.struct;
}
