<template>
  <div class="main">

    <div>
      <h3>Home</h3>
      <p>2 + 2 = {{ 2 + 2 }}</p>
    </div>

    <aside>
      <h3>Connected users</h3>

      <ul>
        <li v-for="(_, userId) in connectedUsers" :key="userId">
          <ShowUser :user-id="userId" />
        </li>
      </ul>
    </aside>
  </div>
</template>

<script setup>
import { useObservable } from '@vueuse/rxjs';
import { connectedUsersStream } from '../libs/BS';

const connectedUsers = useObservable(connectedUsersStream.state$);
</script>

<style lang="scss" scoped>
$min-desktop: 600px;

.main {
  display: flex;

  & > div {
    flex: 1 0 auto;
  }

  & > aside {
    border: 5px solid green;
    border-radius: 12px;
    padding: 15px;
    width: 200px;

    @media (min-width: $min-desktop) {
      width: 400px;
    }
  }
}
</style>
