import { createApp } from 'vue';
import { GlobalEvents } from 'vue-global-events';
import App from './App.vue';
import router from './router';
import ExtLink from './components/ExtLink.vue';
import ShowUser from './components/ShowUser.vue';
import MyModal from './components/MyModal.vue';
import MyDate from './components/MyDate.vue';

import 'boxicons';
import './assets/styles.scss';

createApp(App)
  // plugins
  .use(router)

  // components
  .component('GlobalEvents', GlobalEvents)
  .component('ExtLink', ExtLink)
  .component('ShowUser', ShowUser)
  .component('MyModal', MyModal)
  .component('MyDate', MyDate)

  // directives
  .directive('focus', {
    mounted(el) {
      el.focus();
    },
  })

  // finish
  .mount('#app');
