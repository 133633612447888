import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Chat from '../views/Chat.vue';
import TicTacToe from '../views/TicTacToe.vue';
import TicTacToeBoard from '../views/TicTacToeBoard.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
  },
  {
    path: '/tic-tac-toe',
    name: 'TicTacToe',
    component: TicTacToe,
  },
  {
    path: '/tic-tac-toe/:tableId',
    name: 'TicTacToeBoard',
    component: TicTacToeBoard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
