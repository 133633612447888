<template>
  <div>
    <h3>Tic-Tac-Toe</h3>

    <div v-if="lobbyState">
      <div class="looking">
        <template v-for="(_, userId, idx) in lobbyState.looking" :key="userId">
          <ShowUser :user-id="userId" />
          <template v-if="idx < Object.keys(lobbyState.looking).length - 2">, </template>
          <template v-else-if="idx === Object.keys(lobbyState.looking).length - 2"> and </template>
        </template>
        {{ Object.keys(lobbyState.looking).length > 1 ? 'are' : 'is' }}
        looking at this page
      </div>

      <p>Sitting at: {{ sittingAt }} (position: {{ sittingAs ?? 'null' }})</p>

      <p>
        <button @click="createTable('x')">
          Create table (as X)
        </button>&#x200b;
        <button @click="createTable('o')">
          Create table (as O)
        </button>
      </p>

      <ShowTable v-for="tableId in sortedTableIds" :key="tableId"
                 :table-id="tableId" class="table"
                 :me-sitting="sittingAt === tableId"
                 @sat="sat(tableId, $event)"
                 @stood="stood(tableId)"
                 @started="started(tableId)"
                 :ref="el => { if (el) tables[tableId] = el; else delete tables[tableId]; }" />
    </div>
  </div>
</template>

<script setup>
import {
  ref, onBeforeUnmount, computed, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { useObservable } from '@vueuse/rxjs';
import BS, { myUserId } from '../libs/BS';
import ShowTable from '../components/ShowTable.vue';

const sittingAt = ref(null);
const sittingAs = ref(undefined);

const lobbyStream = BS.newStream('games:tic_tac_toe:lobby');
lobbyStream.join();
const lobbyState = useObservable(lobbyStream.state$);

async function createTable(position) {
  [sittingAt.value, sittingAs.value] = [await lobbyStream.doRequest('create_table', position), position];
}

const sortedTableIds = computed(() => Object.keys(lobbyState.value.tables).sort((a, b) => {
  const sA = a === sittingAt.value ? 1 : 0;
  const sB = b === sittingAt.value ? 1 : 0;
  return -sA + sB;
}));

const tables = {};

async function sat(tableId, position) {
  sittingAt.value = tableId;
  sittingAs.value = position;
}

function stood(tableId) {
  if (sittingAt.value === tableId) {
    sittingAt.value = null;
    sittingAs.value = null;
  }
}

const router = useRouter();
function started(tableId) {
  if (tableId === sittingAt.value) router.push({ name: 'TicTacToeBoard', params: { tableId } });
}

watch(
  () => [sittingAt.value, sittingAs.value],
  async ([newSittingAt], [oldSittingAt, oldSittingAs]) => {
    if (
      oldSittingAt && newSittingAt && newSittingAt !== oldSittingAt
        && tables[oldSittingAt]?.summary?.players?.[oldSittingAs] === myUserId.value
    ) {
      await tables[oldSittingAt].stand();
    }
  },
);

onBeforeUnmount(() => {
  lobbyStream.destroy();
});
</script>

<style lang="scss" scoped>
.table {
  margin-top: 30px;
}

.looking {
  border: 5px solid green;
  border-radius: 12px;
  padding: 15px;
  margin: 15px 0;
}
</style>
