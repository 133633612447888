<template>
  {{ dd.format(props.format) }}
</template>

<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';

// eslint-disable-next-line no-undef
const props = defineProps({
  time: {
    type: Number,
    required: true,
  },
  format: {
    type: String,
    required: true,
  },
});

const dd = computed(() => dayjs.unix(props.time));
</script>
