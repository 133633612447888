import { ref } from 'vue';
import { Observable, from, of } from 'rxjs';
import {
  switchMap, distinctUntilChanged, share,
} from 'rxjs/operators';

import BSManager from './BoardStreams/BSManager';

const BS = new BSManager('/ws');
BS.connect();
export default BS;

export const connectedUsersStream = BS.newStream('connected_users');
connectedUsersStream.join();

export function trackState(streamName) {
  return new Observable((subscriber) => {
    const stream = BS.newStream(streamName);
    stream.join();
    const s = stream.state$.subscribe(subscriber);

    return () => {
      s.unsubscribe();
      stream.destroy();
    };
  });
}

const myUserId$ = BS.connected$.pipe(
  switchMap((x) => {
    if (x) {
      return from(
        connectedUsersStream.doRequest('who_am_i', null, { waitForJoin: true }),
      );
    }
    return of(undefined);
  }),
  distinctUntilChanged(),
  share(),
);

export const myUserId = ref(undefined);
myUserId$.subscribe((x) => { myUserId.value = x; });

export const myProfile = ref(undefined);
myUserId$.pipe(
  switchMap(
    (userId) => {
      if (userId) return trackState(`user:${userId}`);
      return of(undefined);
    },
  ),
).subscribe((profile) => { myProfile.value = profile; });
